var xhandleResize = function() {
    $(window).one("resize", function() {
        if (!Modernizr.touch) {
            $('input[type=text],input[type=password],input[type=checkbox],select').each(function() {
                if ($(this).attr("id") == "confirm") {
                    var offset = $(this).offset();
                    var ol = offset.left - 59;
                    var ot = offset.top + 13;
                    $(this).parent('.checkbox').find('.tooltip').css({
                        'left': ol + 'px',
                        'top': ot + 'px'
                    });
                } else {
                    $(this).blur();
                }
            });
        }
        setTimeout("xhandleResize()", 200);
    });
}
var x_reposition = function() {
    $('input[type=text],input[type=password],input[type=checkbox],select,textarea').find(":visible").each(function() {
        $('input[type=text],input[type=password],input[type=checkbox],select,textarea').find(":visible").each(function() {
            if ($(this).attr("id") == "confirm") {
                var offset = $(this).offset();
                var ol = offset.left - 59;
                var ot = offset.top + 13;
                $(this).parent('.checkbox').find('.tooltip').css({
                    'left': ol + 'px',
                    'top': ot + 'px'
                });
            } else {
                $(this).blur();
            }
        });
    });
};

function doOrientationChange() {
    x_reposition();
}
